// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';
@import '~viewerjs/dist/viewer.css';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
@import '~@delon/theme/system/index';
@import '~@delon/abc/index';
//@import '~@delon/chart/index';
@import '~@delon/theme/layout-default/style/index';
@import '~@delon/theme/layout-blank/style/index';

@import './styles/index';
@import './styles/theme';

//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import '~material-icons/iconfont/material-icons.css';
