@import '~@showye/gantt/main.bundle.scss';

.delete {
  color: #f00;
  cursor: pointer;
  margin-right: 15px;
}

::-webkit-scrollbar {
  width: 5px; /*对垂直流动条有效*/
  height: 5px; /*对水平流动条有效*/
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #dcdfe6;
}

.td-long-deal {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search-box {
  padding: 8px;
}

.search-box input {
  width: 188px;
  margin-bottom: 8px;
  display: block;
}

.search-box button {
  width: 90px;
}

.search-button {
  margin-right: 8px;
}

::ng-deep .text-center {
  text-align: left !important;
}
