/* You can add global styles to this file, and also import other style files */

//* {
//  font-family: Helvetica, Tahoma, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", STXihei, "Microsoft YaHei", SimHei, "WenQuanYi Micro Hei", sans-serif;
//}

img {
  //max-width: 100%;
}

.ant-card-head {
  min-height: auto;

  //& > .ant-tabs {
  //  margin-bottom: 0;
  //}
}

.alain-default__content {
  margin-bottom: 0;
  //margin-left: 214px !important;
}

.alain-default__nav-item {
  display: flex;
  align-items: center;
  padding: 10px 10px;

  .anticon {
    padding: 0 5px;

    &:last-child {
      padding: 0 0 0 5px;
    }

    &:first-child {
      padding: 0 5px 0 0;
    }
  }
}

.text-danger {
  color: #f00;
}

td.indicator {
  background-color: rgba(126, 193, 25, 0.1);
}

.pl-2 {
  padding-left: 20px;
}

.stat-block {
  padding: 24px 0;
  margin-bottom: 16px;
  margin-right: 16px;
  border: 1px solid #f0f0f0;
  flex-grow: 1;
  display: flex;
  justify-content: center;

  &.small:last-child,
  &:last-child {
    margin-right: 0;
  }

  &.small {
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 12px 0;

    & > div {
      padding: 0 7px;

      &:first-child {
        padding-left: 12px;
      }

      &:last-child {
        padding-right: 12px;
      }
    }
  }

  & > div {
    position: relative;
    padding: 0 14px;
    text-align: center;

    & > p:first-child {
      margin-bottom: 4px;
      color: #00000073;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }

    & > p {
      margin: 0;
      color: #000000d9;
      font-size: 30px;
      line-height: 38px;

      & > span {
        color: #00000073;
        font-size: 14px;
      }
    }

    &::after {
      position: absolute;
      top: 20%;
      right: 0;
      width: 1px;
      height: 60%;
      background-color: #f0f0f0;
      content: ' ';
    }

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;

      &::after {
        display: none;
      }
    }
  }
}

.small .stat-block-color {
  min-width: 80px;
  margin-right: 12px;
  padding: 12px;
}

.stat-block-color {
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  flex-grow: 1;
  margin-right: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin: 0;
  }

  .count {
    flex-grow: 1;
    font-size: 24px;
    font-weight: 600;
    color: #555;
    display: flex;
    align-items: baseline;
    justify-content: center;

    span {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .title {
    width: 100%;
    background-color: #ddd;
    text-align: center;
    color: #fff;
    padding: 2px 10px;
  }
}

.special-indicator-card {
  .ant-card-body {
    height: calc(100vh - 155px);
  }
}

.indicator-card {
  .ant-card-body {
    //height: calc(100vh - 160px);
  }

  .ant-card-head-title::before {
    content: ' ';
    padding-left: 10px;
    border-left: 4px solid #2f68e9;
  }

  .ant-card-head-title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
  }
}

.mr-1 {
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
}

.ant-card {
  margin-bottom: 10px;
}

.small.ant-card .ant-card-body {
  padding: 12px 24px;
}

.tab.ant-card .ant-card-body {
  padding-bottom: 0;

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin-bottom: 0;
  }
}

.nb-page-header {
  display: flex;
  justify-content: space-between;
  margin-left: -22px;
  margin-right: -24px;
  padding: 5px 24px;

  .title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
  }
}

.nb-divider {
  width: 100%;
  height: 20px;
  border-bottom: 1px dashed #d1d1d1;
}

.equal-height {
  display: flex;
  align-items: stretch;
}

.equal-height-column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ant-picker-calendar-mode-switch {
  display: none;
}

.ant-card-body {
  padding: 20px;
}

.indicator-tabs {
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 6vw;
  }
}
